<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="bg-white p-3 mb-3 rounded" v-if="teamLoader == 0">
          <TeamDetailsTop
            :selectedRoomTeam="selectedRoomTeam"
            :currentUserId="currentUserId"
            :currentIsAdmin="currentIsAdmin"
            :currentIsFan="currentIsFan"
            :currentTeamIsActive="currentTeamIsActive"
          />
          <TeamDetailsTab :teamId="teamId" />
          <ScheduleData />
          <!-- <div class="friends-options pt-2 px-0  pb-0">
            <ul class="nav nav-tabs mb-0" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="events-tab"
                  data-toggle="tab"
                  data-target="#events"
                  type="button"
                  role="tab"
                  aria-controls="Events"
                  aria-selected="false"
                >
                  Events
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="team-tab"
                  data-toggle="tab"
                  data-target="#team"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Schedule
                </button>
              </li>

              <li class="nav-item" role="presentation">
                <button
                  class="nav-link "
                  id="tournament-tab"
                  data-toggle="tab"
                  data-target="#tournament"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Teammates
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="sponser-tab"
                  data-toggle="tab"
                  data-target="#sponser"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Statistics
                </button>
              </li>
            </ul>

            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade "
                id="team"
                role="tabpanel"
                aria-labelledby="team-tab"
              >
                <ScheduleData
                  :selectedRoomTeam="selectedRoomTeam"
                  :currentUserId="currentUserId"
                  :currentIsAdmin="currentIsAdmin"
                  :currentIsFan="currentIsFan"
                  :currentTeamIsActive="currentTeamIsActive"
                />
              </div>

              <div
                class="tab-pane fade "
                id="tournament"
                role="tabpanel"
                aria-labelledby="tournament-tab"
              >
                <GameTimeTeammates
                  :selectedRoomTeam="selectedRoomTeam"
                  :currentUserId="currentUserId"
                  :currentIsAdmin="currentIsAdmin"
                  :currentIsFan="currentIsFan"
                  :currentTeamIsActive="currentTeamIsActive"
                />
              </div>
              <div
                class="tab-pane fade show active"
                id="events"
                role="tabpanel"
                aria-labelledby="events-tab"
              >
                <GameEvents
                  :selectedRoomTeam="selectedRoomTeam"
                  :currentUserId="currentUserId"
                  :currentIsAdmin="currentIsAdmin"
                  :currentIsFan="currentIsFan"
                  :currentTeamIsActive="currentTeamIsActive"
                  :teamEventInfo="teamEventInfo"
                  :teamId="teamId"
                  :showLoader="showLoader"
                />
              </div>

              <div
                class="tab-pane fade"
                id="sponser"
                role="tabpanel"
                aria-labelledby="sponser-tab"
              >
                <GameTimeStats
                  :selectedRoomTeam="selectedRoomTeam"
                  :showLoader="showLoader"
                  :gameStats="gameStats"
                  :teamId="teamId"
                />
              </div>
            </div>
          </div> -->
        </div>
        <!-- loader -->
        <div class="order-history-page" v-if="teamLoader == 1">
          <div class="order-history">
            <div class="lds-dual-ring-media"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScheduleData from "../components/GameTime/ScheduleData.vue";
// import GameTimeStats from "../components/GameTime/GameTimeStats.vue";
// import GameEvents from "../components/GameTime/GameEvents.vue";
// import GameTimeTeammates from "../components/GameTime/GameTimeTeammates.vue";
import TeamDetailsTab from "../components/GameTime/TeamDetailsTab.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import TeamDetailsTop from "../components/GameTime/TeamDetailsTop.vue";
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import * as firestoreService from "../database/firestore";
import axios from "axios";

export default {
  name: "GameTeamDetail",

  components: {
    Navbar,
    LeftMenu,
    TeamDetailsTop,
    TeamDetailsTab,
    ScheduleData,
    // GameTimeTeammates,
    // GameTimeStats,
    Banner,
    // GameEvents,
  },

  data() {
    return {
      teamId: "",
      currentIsAdmin: "0",
      currentIsFan: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      gameStats: [],
      currentUserId: "",
      userId: localStorage.getItem("userId"),
      allUsers: [],
      teamLoader: 1,
      showLoader: 1,
      teamDataInfo: {},
      teamEventInfo: [],
    };
  },

  methods: {
    async getCurrentChatId() {
      if (this.currentUserId == "") {
        const promiseUser = await firestoreService.getUserCid(this.userId);
        this.foundCurrentUser = promiseUser;
        if (this.foundCurrentUser.data[0].id != undefined) {
          this.currentUserId = this.foundCurrentUser.data[0].id;
        } else {
          this.getCurrentChatId();
        }
      }
    },
    async getTeamData() {
      this.teamLoader = 1;
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(this.teamId);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData = {};
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData = teamData.users;
        teamData.users = {};
        this.selectedRoomTeam = teamData;
      });

      // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users = [];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          var chechUrl;
          var url =
            this.$userProfileStorageURL +
            foundUser.c_id +
            "/profile_picture.jpg?v=" +
            this.time;

          this.urlExist(url, (exists) => {
            if (exists) {
              chechUrl = "yes";
              foundUser.avatar = url;
              console.log(chechUrl);
            } else {
              chechUrl = "no";
              foundUser.avatar = require("../assets/images/user-default-new.png");
            }
          });
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users = users;

      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
            var chechUrl;
            var url =
              this.$userProfileStorageURL +
              member.c_id +
              "/profile_picture.jpg?v=" +
              this.time;
            this.urlExist(url, (exists) => {
              if (exists) {
                chechUrl = "yes";
                member.avatar = url;
                console.log(chechUrl);
              } else {
                chechUrl = "no";
                member.avatar = require("../assets/images/user-default-new.png");
              }
            });
          }
          if (member.id == this.currentUserId && member.adminRole == "1") {
            this.currentIsAdmin = "1";
          }
          if (member.id == this.currentUserId && member.adminRole == "0") {
            this.currentIsAdmin = "0";
          }
          if (member.id == this.currentUserId && member.fanRole == "1") {
            this.currentIsFan = "1";
          }
          if (member.id == this.currentUserId && member.fanRole != "1") {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (member.id == this.currentUserId && member.roleStatus == 1) {
            this.currentTeamIsActive = 1;
          }
          if (member.id == this.currentUserId && member.roleStatus == 2) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId = this.selectedRoomTeam.id;
      this.teamLoader = 0;
    },
    getTeamInfo() {
      const formData = new FormData();
      formData.append("team_id", this.teamId);
      axios
        .post(process.env.VUE_APP_API_URL + "chat/getTeamData", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamDataInfo = response.data.data;
            if (this.teamDataInfo.team) {
              this.$root.$emit("onBaseShowAvg", this.teamDataInfo);
            }
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getTeamEvents() {
      const formData = new FormData();
      formData.append("team_id", this.teamId);
      // console.log(formData)
      axios
        .post(process.env.VUE_APP_API_URL + "event/eventListing", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamEventInfo = response.data.data;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    teamGameStats() {
      this.showLoader = 1;
      var formData = new FormData();
      formData.append("team_id", this.teamId);
      return axios
        .post(
          process.env.VUE_APP_API_URL + "game/getStatsForTeam",
          formData,
          {}
        )
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.gameStats = response.data.data.gameLineUps;
            this.showLoader = 0;
          } else {
            if (response.data.statusCode == 404)
              this.flashMessage.error({
                title: "Error",
                message: response.data.message,
                time: 5000,
                blockClass: "custom-block-class",
              });
            this.showLoader = 0;
          }
        });
    },
    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },

    // URL exist ----------------------------------------------------------------
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
  },
  mounted() {
    this.$root.$on("tabChange", (tab) => {
      this.changeTab(tab);
    });

    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
    if (this.teamId) {
      this.getCurrentChatId();
      this.getTeamData();
      this.getTeamInfo();
      this.getTeamEvents();
    } else {
      this.$router.push({ name: "PageNotFound" });
    }

    this.$root.$on("gameTimeScheduledReload", () => {
      if (this.teamId) {
        this.getCurrentChatId();
        this.getTeamData();
        this.getTeamInfo();
      }
    });
  },
};
</script>

<style scoped>
.nav-tabs {
  border-bottom: 2px solid #e5f2fe;
  margin-bottom: 16px;
}
.nav-tabs .nav-link {
  border: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  white-space: nowrap;
  display: flex;
  margin: 0 !important;
  align-items: center;
  position: relative;
  color: #557491 !important;
}
.nav-tabs .nav-link:hover {
  border: none;
  color: #188ef5 !important;
}
.nav-tabs .nav-link.active {
  color: #188ef5 !important;
  border-bottom: 4px solid #188ef5;
  border-radius: 0px;
}
</style>
