<template>
  <div class="page-not-found-wrapper">
    <img src="../../assets/page.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
