<template>
  <div>
    <div v-if="showLoader === 1" class="card-event mt-3">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>

      <br />

      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-heading />
      </content-placeholders>
    </div>
    <div v-else-if="schedulesList.length === 0 && showLoader === 0">
      <div class="no-chat-text">
        <p>No game is scheduled yet</p>
      </div>
    </div>
    <div v-if="schedulesList.length > 0" class="live-league-wrap">
      <div v-for="(schedule, index) in schedulesList" :key="index">
        <div class="cursor-pointer" @click="callSingleBoxModal(schedule.guid)">
          <div
            class="live-league position-relative"
            v-if="schedule.game_live == 1"
          >
            <div class="inner-sec">
              <div class="live-tag">
                <span>Live</span>
              </div>
              <!-- <div class="video-icon">
                <img src="../../assets/images/video.png" alt="" />
              </div> -->
            </div>
            <div class="league" v-if="schedule.event !== null">
              <span>{{ schedule.event.eventName }}</span>
            </div>
          </div>
          <div class="live-league league-only" v-else>
            <div class="league" v-if="schedule.event !== null">
              <span>{{ schedule.event.eventName }}</span>
            </div>
          </div>
          <div
            class="schedule-info"
            :class="{
              'last-schedule-info': index === schedulesList.length - 1,
            }"
          >
            <div>
              <span class="day-date">{{ schedule.exactDateFormat }}</span>
              <div class="location-info">
                <img
                  src="../../assets/images/location.png"
                  @click="openGoogleMap($event, schedule)"
                  alt=""
                />

                <div class="content">{{ schedule.park_address }}</div>
              </div>
              <div class="opponent-info">
                <span class="vs">vs</span>
                <span class="oppo-name">{{ schedule.opponent_name }}</span>
              </div>
              <div
                class="oppo-para"
                v-if="schedule.comment !== null && schedule.comment !== ''"
              >
                {{ schedule.comment }}
              </div>
            </div>
            <div class="played-data">
              <span class="tie" v-if="schedule.game_live == 1"
                >{{ schedule.team_score }} - {{ schedule.opponent_score }}</span
              >

              <span class="tie" v-else-if="schedule.win_status == 0"
                >{{ schedule.team_score }} - {{ schedule.opponent_score }}</span
              >
              <span class="win-data" v-else-if="schedule.win_status == 1"
                >W{{ schedule.team_score }} -
                {{ schedule.opponent_score }}</span
              >
              <span class="loss-data" v-else-if="schedule.win_status == 2"
                >L{{ schedule.team_score }} -
                {{ schedule.opponent_score }}</span
              >
              <span class="tie" v-else>
                {{ schedule.team_score }} - {{ schedule.opponent_score }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="noSchedules" v-observe-visibility="handleScrolledToButtom"></div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "ScheduleData",
  data() {
    return {
      live: true,
      won: true,
      lost: true,
      played: true,
      page: 1,
      size: 5,
      showLoader: 1,
      lastPage: 1,
      schedulesList: [],
      noSchedules: 0,
    };
  },
  methods: {
    async fetch() {
      this.showLoader = 1;
      let schedules = await axios.post(
        `game/scheduledGameListing?page=${this.page}&size=${this.size}`,
        { team_id: this.teameId }
      );

      if (schedules.data.statusCode == 200) {
        this.schedulesList.push(...schedules.data.data.data);
        this.lastPage = schedules.data.data.last_page;
        this.noSchedules = schedules.data.data.length > 0 ? 0 : 1;
      } else {
        this.errorMessage = schedules.data.message;
      }
      this.showLoader = 0;
    },

    callSingleBoxModal(guid) {
      var combineUrl = this.teameId + "-" + guid;
      const urlToOpen = `/score-game/${combineUrl}`;
      window.location.href = urlToOpen;
    },
    openGoogleMap(event, item) {
      event.stopPropagation(); // Stop the event from propagating to the parent div
      const strlatLng = item["lan"] + "," + item["long"];
      const urlSuffix = strlatLng
        ? strlatLng
        : item["address"] + ", " + item["city"] + ", " + item["zipCode"];

      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },

    handleScrolledToButtom(isVisible) {
      if (this.page >= this.lastPage) {
        return;
      }
      if (!isVisible) {
        return;
      }
      this.page++;
      this.fetch();
    },
  },

  mounted() {
    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teameId = segment_array.pop();

    this.fetch(1);
    this.page = 1;
    this.lastPage = 1;
    this.schedulesList = [];
  },
};
</script>

<style></style>
