<template>
  <div>
    <!-- loader -->
    <div class="play-loader" v-if="showLoader == 1" >
      <content-placeholders :rounded="true" >
         <content-placeholders-text :lines="2" />
      </content-placeholders>
    </div>
    

    <div class="play-info" v-if="showLoader == 0">
      <div class="game-play">
        <span>G</span>
        <span>{{ totalGame }}</span>
      </div>
      <div class="game-win">
        <span>W</span>
        <span>{{ win }}</span>
      </div>
      <div class="game-loss">
        <span>L</span>
        <span>{{ loss }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Play",
  data() {
    return {
      showLoader: 1,
      totalGame: 0,
      win: 0,
      loss: 0,
      team_Id: null,
    };
  },
  props: {
    teamId: {
      type: String,
    },
  },
  
  methods: {
    async teamRecord() {
      this.showLoader = 1;
      const formData = new FormData();

      if (this.teamId) {
        formData.append("team_id", this.teamId);
      } else {
        var url = window.location.pathname;
        var segment_array = url.split("/");
        this.team_Id = segment_array.pop();
        if (this.team_Id) {
          formData.append("team_id", this.team_Id);
        }
      }
      let report = await axios.post("game/stats", formData);

      if (report.data.statusCode == 200) {
        this.totalGame = report.data.data.total_games;
        this.win = report.data.data.win;
        this.loss = report.data.data.lost;
        this.showLoader = 0;
      } else {
        this.showLoader = 0;
      }
    },
  },
  mounted() {
    this.teamRecord();
  },
};
</script>

<style>
</style>