<template>
  <div>
    <ChatSystem :currentUserId="currentUserId" :rooms="rooms" />
    <div class="team-info-wrap">

      <div class="row m-0">
            <div class="col-md-4 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
                <input
                    class=""
                    id="eventType"
                    v-model="filter.name"
                    @input="getFilters()"
                    placeholder="Enter Team Name"
                    />
                <span v-if="filter.name" class="clear-icon" @click="clearName()"><img src="../../assets/cross-icon.png"/></span>
            </div>

            <div  class="col-md-4 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
                <select
                class=""
                id="ageGroup"
                v-model="filter.age"
                @change="getFilters()"
                >
                  <option value="" selected> Select Age Group </option>
                  <option v-for="option in ageOptions" :key="option.name" :value="option.name">
                    {{ option.name }}
                  </option>
                </select>
                <span v-if="filter.age" class="clear-icon" @click="clearAge()"><img src="../../assets/cross-icon.png"/></span>

            </div>
            <div  class="col-md-4 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end">
                <select
                class=""
                id="gender"
                v-model="filter.gender"
                @change="getFilters()"
                >
                  <option value="" selected>Select Gender</option>
                  <option v-for="option in genderOptions" :key="option.id" :value="option.name" >
                    {{ option.name }}
                  </option>
                </select>
                <span v-if="filter.gender" class="clear-icon" @click="clearGender()"><img src="../../assets/cross-icon.png"/></span>

            </div>
        </div>



      <div class="team-info-wrap-top justify-content-end">
          <button class="btn btn-primary" @click="callModal()">Add Team</button>
      </div>

      <TeamData :teamInfoLoader="teamInfoLoader" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TeamData from "./TeamData.vue";
import ChatSystem from "../Teams/ChatSystem.vue";
export default {
  name: "TeamInfo",
  components: { TeamData, ChatSystem },
  data() {
    return {
      
      filter: {
        name: "",
        age: "",
        gender: "",
      },
      messages: [],
      ageOptions: [],
      genderOptions: [
        { name: "Male", id: "Male" },
        { name: "Female", id: "Female" },
        { name: "Coed", id: "Coed" },
      ],
    };
  },
  props: {
    teamInfoLoader: {
      type: Number,
    },
    currentUserId: {
      type: String,
    },
    rooms: {
      type: Array
    },
  },
  methods: {
    // emitFilterData() {
    //   this.$root.$emit("filter-updated", this.filter);
    // },
    callModal: function () {
      this.$root.$emit("openAddTeamModal");
    },
    getAgeGroup() {
      axios
        .get(`${process.env.VUE_APP_API_URL}getAgeGroup`)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.ageOptions = response.data.data.list;
          } else {
            this.errorMessage = response.data.message;
          }
        });
    },
    getFilters(){
        const filteredteams={ name: this.filter.name, age: this.filter.age, gender: this.filter.gender };
        this.$root.$emit("filtersData", filteredteams);
    },
      clearName(){
        this.filter.name = "";
        this.getFilters();
      },
      clearAge(){
        this.filter.age = "";
        this.getFilters();
      },
      clearGender(){
        this.filter.gender = "";
        this.getFilters();
      },
      
  },
  mounted(){
    this.getAgeGroup();
  }
};
</script>

<style scoped>
.pinned-options{
   top: 25%;
    left: inherit;
    margin-right: -11%;
}
.chat-filter-options {
  background: #cce6fd;
  border-radius: 5px;
  height: 35px;
  padding: 0 5px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-counter {
  background: #254c72;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  margin: 0 5px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.form-group select {
    border: 1px solid #ced4da !important;
}
</style>