<template>
  <div>
    <!-- loader -->
      <div class="row no-gutters mb-3" v-if="showLoader === 1">

        <div class="col-12 col-md-6 col-lg-4">
          <div class="bg-white rounded p-3 mr-md-3">
            <content-placeholders  :rounded="true">
              <content-placeholders-text :lines="3" />
            </content-placeholders>
          </div>
        </div>

        <div class="col-12 d-none d-md-block col-md-6 col-lg-4 ">
          <div class="bg-white rounded p-3 mr-lg-3">
            <content-placeholders  :rounded="true">
              <content-placeholders-text :lines="3" />
            </content-placeholders>
          </div>
        </div>

        <div class="col-12 d-none d-lg-block col-lg-4">
          <div class="bg-white rounded p-3">
            <content-placeholders  :rounded="true">
              <content-placeholders-text :lines="3" />
            </content-placeholders>
          </div>
        </div> 

      </div>

      <!-- If no live game  -->
      <div v-else-if="gamesData.length === 0 && showLoader === 0">
        <div class="no-chat-text mb-3 bg-white py-3 mt-0 rounded">
            <p class="text-center">No live games for today.</p>
        </div>
      </div>


    <VueSlickCarousel v-if="gamesData.length > 0" v-bind="settings">
      <div v-for="game in gamesData" :key="game.id" class="card-menu-wrapper">
        <ul class="menu">
          <li class="item">
            <div class="wrapper-top ">
              <div class="inner-sec">
                <div class="live-tag">
                  <span>Live</span>
                </div>
                <!-- <div class="video-icon">
                  <img src="../../assets/images/video.png" alt="" />
                </div> -->
              </div>
              <div class="league text-right">
                <span>{{ game.event.eventName }}</span>
              </div>
            </div>
            <div class="info-section">
              <div class="left-portion">
                <div class="info">
                  <div class="image-team">
                    <img src="../../assets/images/team-avatar.png" alt="" />
                  </div>
                  <span>{{ game.visiting_team }}</span>
                </div>
                <div class="info">
                  <div class="image-team">
                    <img src="../../assets/images/team-avatar.png" alt="" />
                  </div>
                  <span>{{ game.home_team }}</span>
                </div>
              </div>
              <div class="right-portion">
                <div :class="{ 'right-side-stat': game.isVisitingTeamBatting }">{{ game.visiting_score }}</div>
                <div :class="{ 'right-side-stat': !game.isVisitingTeamBatting }">{{ game.home_score }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import axios from "axios";

export default {
  name: "LiveCard",
  data() {
    return {
      showLoader: 1,
      teams_ids: {},
      gamesData: [],
      settings: {
        dots: false,
        infinite: false,
        initialSlide: 0,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },
  components: { VueSlickCarousel },
  methods: {
    getLiveScore() {
      const teamData = {
        team_ids: [this.teams_ids],
      };
      teamData.team_ids = teamData.team_ids.join(",");
      this.showLoader = 1;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}game/gameListingMutlipleTeams`,
          teamData
        )
        .then((response) => {
          this.gamesData = response.data.data; // Store received game data
          this.showLoader = 0;
        })
        .catch((error) => {
          console.error("Error fetching live score:", error);
          this.showLoader = 0;
        });
    },
  },
  mounted() {
    this.$root.$on("gameTimeLiveCard", (teamIds) => {
      this.teams_ids = teamIds;
      this.getLiveScore();
    });
  },
};
</script>
<style scoped>
.right-side-stat {
  background-color: #188ef5;
    color: #ffffff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    text-align: center;
    width: 25px;
    height: 25px;
    line-height: 25px;
}
</style>