<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side pr-3">
        <div class="bg-white p-3 mb-3 rounded" v-if="teamLoader==0">
          <TeamDetailsTop  
            :selectedRoomTeam="selectedRoomTeam"
            :currentUserId="currentUserId"
            :currentIsAdmin="currentIsAdmin"
            :currentIsFan="currentIsFan"
            :currentTeamIsActive="currentTeamIsActive"
            />
          <TeamDetailsTab :teamId="teamId" />
          <GameTimeTeammates 
            :selectedRoomTeam="selectedRoomTeam"
            :currentUserId="currentUserId"
            :currentIsAdmin="currentIsAdmin"
            :currentIsFan="currentIsFan"
            :currentTeamIsActive="currentTeamIsActive"
          />
        </div>
        <div class="order-history-page" v-if="teamLoader == 1">
          <div class="order-history">
            <div class="lds-dual-ring-media"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
  <script>
import TeamDetailsTop from "../components/GameTime/TeamDetailsTop.vue";
import TeamDetailsTab from '../components/GameTime/TeamDetailsTab.vue';
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import GameTimeTeammates from '../components/GameTime/GameTimeTeammates.vue';
import * as firestoreService from "../database/firestore";

export default {
  name: "GameTeammates",

  components: {
    Navbar,
    LeftMenu,
    TeamDetailsTop,
    TeamDetailsTab,
    GameTimeTeammates,
    Banner
  },

 data() {
    return{
      teamId:'',
      currentIsAdmin: "0",
      currentIsFan: "0",
      currentTeamIsActive: 1,
      selectedRoomTeam: {},
      currentUserId:"",
      userId: localStorage.getItem("userId"),
      allUsers:[],
      teamLoader:1,
    };
  },

  computed: {},

  methods: {
    async getCurrentChatId()
    {
      if (this.currentUserId == "") {
          const promiseUser = await firestoreService.getUserCid(this.userId);
          this.foundCurrentUser = promiseUser;
          if (this.foundCurrentUser.data[0].id != undefined) {
            this.currentUserId = this.foundCurrentUser.data[0].id;
          } else {
            this.getCurrentChatId();
          }
      }
    },
    async getTeamData()
    {
      this.teamLoader=1;
      const rawRoom = [];
      const promise = await firestoreService.getRoomById(this.teamId);
      rawRoom.push(promise);

      const foundRoom = await Promise.all(rawRoom);
      let usersData={}; 
      Object.keys(foundRoom).forEach((key) => {
        const teamData = foundRoom[key];
        usersData=teamData.users;
        teamData.users = {};
        this.selectedRoomTeam=teamData;
      });

       // check role if team exits or user is member
      let roles = this.selectedRoomTeam.userRole;
      if (usersData != undefined) {
        var admincount = 0;
        for (const userRole of roles) {
          if (userRole.id == this.currentUserId) {
            admincount++;
          }
        }
        if (admincount == 0 ) {
          this.$router.push({ name: "PageNotFound" });
        }
      } else {
        this.$router.push({ name: "PageNotFound" });
      }

      await this.makeUserArray(usersData);
      const users=[];
      usersData.forEach((userId) => {
        const foundUser = this.allUsers.find((user) => user?._id === userId);
        if (foundUser) {
          var chechUrl;
          var url =
            this.$userProfileStorageURL +
            foundUser.c_id +
            "/profile_picture.jpg?v=" +
            this.time;

          this.urlExist(url, (exists) => {
            if (exists) {
              chechUrl = "yes";
              foundUser.avatar = url;
              console.log(chechUrl);
            } else {
              chechUrl = "no";
              foundUser.avatar = require("../assets/images/user-default-new.png");
            }
          });
          users.push(foundUser);
        }
      });

      this.selectedRoomTeam.users=users;
      
      // assign admin role
      let tempMembers = this.selectedRoomTeam.users;

      // Set Users Role and Find if current User is admin
      for (const role of roles) {
        for (const member of tempMembers) {
          if (role.id == member.id) {
            member.adminRole = role.admin;
            member.fanRole = role.fan;
            member.roleStatus = role.status;
            member.uniform = role.uniform;
            member.bat_size = role.bat_size;
            member.bat_details = role.bat_details;
            member.hat_size = role.hat_size;
            member.shirt_size = role.shirt_size;
            member.pant_size = role.pant_size;
             var chechUrl;
            var url =
              this.$userProfileStorageURL +
              member.c_id +
              "/profile_picture.jpg?v=" +
              this.time;
            this.urlExist(url, (exists) => {
              if (exists) {
                chechUrl = "yes";
                member.avatar = url;
                console.log(chechUrl);
              } else {
                chechUrl = "no";
                member.avatar = require("../assets/images/user-default-new.png");
              }
            });
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "1"
          ) {
            this.currentIsAdmin = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.adminRole == "0"
          ) {
            this.currentIsAdmin = "0";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole == "1"
          ) {
            this.currentIsFan = "1";
          }
          if (
            member.id == this.currentUserId &&
            member.fanRole != "1"
          ) {
            this.currentIsFan = "0";
          }

          // assign current user team status
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 1
          ) {
            this.currentTeamIsActive = 1;
          }
          if (
            member.id == this.currentUserId &&
            member.roleStatus == 2
          ) {
            this.currentTeamIsActive = 2;
          }
        }
      }

      this.selectedRoomTeam.roomId=this.selectedRoomTeam.id;
      this.teamLoader=0;
    },
    async makeUserArray(users) {
      const roomUserIds = users;

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },

      // URL exist ----------------------------------------------------------------
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },
  },
  mounted() {
    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
     if(this.teamId)
      {
        this.getCurrentChatId();
        this.getTeamData();  
      }
      else
      {
          this.$router.push({ name: "PageNotFound" });
      }

    this.$root.$on("gameTimeTeammatesReload", () => {
      if(this.teamId)
      {
        this.getCurrentChatId();
        this.getTeamData();  
      }
    });
  },
};
</script>
      