<template>
  <div>
    <MembersInfos
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
        :currentIsAdmin="currentIsAdmin"
        :currentIsFan="currentIsFan"
        :currentTeamIsActive="currentTeamIsActive"
      />

      <TeamInviteList
        :selectedRoomTeam="selectedRoomTeam"
        :currentIsAdmin="currentIsAdmin"
      />
  </div>
</template>

<script>
import TeamInviteList from "../Teams/ChatRight/ChatSettingComponents/TeamInviteList.vue";
import MembersInfos from "../Teams/ChatRight/ChatSettingComponents/MembersInfos.vue";
export default {
  name: "GameTimeTeammates",
   components: {
    TeamInviteList,
    MembersInfos,
  },
  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
};
</script>

<style>
</style>