<template>
  <div>
    <!-- Send Message TO Team -->
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#teamModalDirect"
      ref="openModalTeamMessage"
    ></button>
    <div
      class="modal fade"
      id="teamModalDirect"
      role="dialog"
      aria-labelledby="teamModalDirect"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title" id="individualModalDirectLabel">
              Send Message to {{ selectedRoomTeam.teamName }}
            </h6>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeTeamDirectModal"
            >
              <img src="../../assets/close.png" alt="" />
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group mb-0">
              <textarea
                type="text"
                class="form-control message"
                required
                rows="5"
                placeholder="Message"
                id="teamMessage"
                v-model="teamMessage"
                @input="(e) => (teamMessage = e.target.value)"
                @keyup="errorNullTeam()"
              ></textarea>
            </div>
          </div>
          <div class="event-btns-wrapper px-3 pb-3 pt-0 justify-content-end">
            <button
              v-if="this.isDisabledTeam == false"
              type="button"
              class="btn btn-primary w-25"
              block
              v-on:click="sendMessage(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
            <button
              v-else-if="this.isDisabledTeam == true"
              type="button"
              class="btn btn-primary-disabled w-25"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Create chat modal -->
  </div>
</template>
  <script>

import { mapGetters, mapActions } from "vuex";

export default {
  name: "TeamDirectMessageModal",
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  data() {
    return {
      isDisabledTeam: true,
      isLoadingArray: [],
      teamMessage: "",
      selectedRoomTeam: {},
      currentUserId: "",
    };
  },

  methods: {
     ...mapActions("team", ["sendMessageToTeam"]),
    // ---- Send Message ----------------------------------------------------------------------
    errorNullTeam: function () {
      if (this.teamMessage == "") {
        this.isDisabledTeam = true;
      } else {
        this.isDisabledTeam = false;
      }
    },

    // ---- Individual ----------------------------------------------------------------------
    async sendMessage(index) {
      this.isDisabledTeam = true;
      this.$set(this.isLoadingArray, index, true);

        if (this.teamMessage) {
              const chatData = [];
              chatData["roomId"] = this.selectedRoomTeam.id;
              chatData["userCount"] = this.selectedRoomTeam.userCount;
              chatData["currentUserId"] = this.currentUserId;
              chatData["messageTeam"] = this.teamMessage;

             this.sendMessageToTeam(chatData).then(() => {
              this.teamMessage = "";
                var elem = this.$refs.closeTeamDirectModal;
                if (elem) {
                  elem.click();
                  this.isDisabledTeam = true;
                  this.$set(this.isLoadingArray, index, false);
                  this.$router.push({ name: "Teams" });
                }
             });
        }
    },
  },
  mounted() {
    this.$root.$on(
      "fetchTeamDirectMessageModal",
      (selectedRoomTeam, currentUserId) => {
        this.selectedRoomTeam = selectedRoomTeam;
        this.currentUserId = currentUserId;
        this.teamMessage = "";
        this.isDisabledTeam = true;
        this.$set(this.isLoadingArray, 0, false);
        var elem = this.$refs.openModalTeamMessage;
        if (elem) {
          elem.click();
        }
      }
    );
  },
};
</script>
  