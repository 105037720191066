<template>
  <div class="" v-if="user">
    <div class="invalid mb-26" v-if="errorMessage">
      {{ errorMessage }}
    </div>
    <div v-if="posts.length">
      <div class="post-view" v-for="post in posts" :key="post.id">
        <div class="user-info" style="margin-bottom: 16px">
          <div class="user-img">
            <span v-if="user.user_link == post.user_link">
              <router-link to="/profile" exact style="color: black">
                <img
                  v-if="post.propicture == 'yes'"
                  :src="
                    Storage_URL +
                      post.user_id +
                      '/profile_picture.jpg?v=' +
                      time
                  "
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                  
                />
              </router-link>
            </span>
            <span v-else>
              <router-link
                :to="/profile/ + post.user_link"
                exact
                style="color: black"
              >
                <img
                  v-if="post.propicture == 'yes'"
                  :src="
                    Storage_URL +
                      post.user_id +
                      '/profile_picture.jpg?v=' +
                      time
                  "
                  alt=""
                />
                <img
                  v-else
                  src="../../assets/images/user-default-new.png"
                  alt=""
                 
                />
              </router-link>
            </span>
          </div>
          <div class="user-details mr-auto">
            <span v-if="user.user_link == post.user_link">
              <router-link
                to="/profile"
                exact
                style="color: black"
                class="user-name ml-0"
                >{{ post.user_name }}</router-link
              >
            </span>
            <span v-else>
              <router-link
                :to="/profile/ + post.user_link"
                exact
                style="color: black"
                class="user-name ml-0"
                >{{ post.user_name }}</router-link
              >
            </span>
            <p>{{ post.created_at | formatDate }}</p>
          </div>
          <PostEdit
            :items="post"
            v-if="user.id == post.user_id"
            :openTab="1"
          ></PostEdit>
        </div>
        <div class="post-content">
          <PostContent :items="post.caption"></PostContent>
          <div class="post-images">
            <div class="gallery-container">
              <lightbox :items="post.post_images" class="newPostC"></lightbox>
            </div>
          </div>
        </div>
        <PeopleComments :items="post" />
      </div>
    </div>

    <div v-if="noPost == 1" class="see-all-seen-post-wrapper mt-0">
      <p>No posts found.</p>
    </div>
    <content-placeholders
      class="post-view"
      :rounded="true"
      v-if="showLoader == 1"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import lightbox from "../ImageGrid/lightbox";
import "../ImageGrid/lightbox";
import "../ImageGrid/lightbox.css";
import PostContent from "../Posts/PostContent";
import PostEdit from "../Posts/PostEdit";
import PeopleComments from "./PeopleComments.vue";

export default {
  name: "PostView",
  components: {
    lightbox,
    PostContent,
    PostEdit,
    PeopleComments,
  },
  data() {
    return {
      time: this.$time,
      Storage_URL: this.$userProfileStorageURL,
      errorMessage: null,
      successMessage: null,
      posts: [],
      page: 1,
      noPost: 0,
      showLoader: 0,
      API_URL: this.$userProfileStorageURL,
      postOptions: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    async fetch() {
      this.showLoader = 1;
       var post_id = localStorage.getItem("post_id_single");
      let posts = await axios.get(
        `post/singlePost?page=${this.page}&post_id=${post_id}`
      );
      if (posts.data.statusCode == 200) {
        if (posts.data.data.searchedPost) {
          this.posts.push(...posts.data.data.searchedPost);
        }
        this.noPost = posts.data.data.searchedPost.length > 0 ? 0 : 1;
      } else {
        this.errorMessage = posts.data.message;
      }
      this.showLoader = 0;
    },
  },
  mounted() {
    this.fetch(1);
  },
};
</script>
