<template>
  <div>
    <div class="row m-0">
      <div
        class="col-md-2 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
      >
        <select
          class=""
          v-model="allFilters.eventFilter"
          @change="applyFilter()"
        >
          <option value="" selected>Select Event</option>
          <option
            v-for="option in teamEventInfo"
            :value="option.id"
            :key="option.id"
          >
            {{ option.eventName }}
          </option>
        </select>
        <span
          v-if="allFilters.eventFilter"
          class="clear-icon"
          @click="clearEvents()"
          ><img src="../../assets/cross-icon.png"
        /></span>
        <div class="shop-products-img-loader" v-if="eventLoader">
          <div class="lds-dual-ring-media"></div>
        </div>
      </div>

      <div
        class="col-md-2 px-1 form-group control-group mb-0 d-flex align-items-center justify-content-end"
      >
        <select
          class=""
          v-model="allFilters.associationFilter"
          @change="applyFilter()"
        >
          <option value="" selected>Select Association</option>
          <option
            v-for="option in associationOptions"
            :value="option"
            :key="option"
          >
            {{ option }}
          </option>
        </select>
        <span
          v-if="allFilters.associationFilter"
          class="clear-icon"
          @click="clearAssociation()"
          ><img src="../../assets/cross-icon.png"
        /></span>
      </div>
    </div>


    <b-row class="no-gutters" v-if="gameLineUps.length > 0 && showLoader == 0">
  <div class="col-12 products-column mt-3">
    <div class="event-card">
      <div class="table-responsive mb-0">
        <b-table :items="gameLineUps" :fields="fields" hover responsive class="b-datatable stat-datatable">
          
          <template v-slot:top-row class="tbody-tr-attr">
              <th>Total</th>
              <th class="text-center"></th>
              <th class="text-center"></th>
              <th class="text-center" v-if="showBase">{{ totalStats.on_base_avg }}</th>
              <th class="text-center" v-if="!showBase">{{ totalStats.on_base_avg }}</th>
              <th class="text-center" v-if="!showBase">{{ totalStats.average }}</th>
              <th class="text-center">{{ totalStats.ab }}</th>
              <th class="text-center">{{ totalStats.h }}</th>
              <th class="text-center">{{ totalStats.one_b }}</th>
              <th class="text-center">{{ totalStats.two_b }}</th>
              <th class="text-center">{{ totalStats.three_b }}</th>
              <th class="text-center">{{ totalStats.hr }}</th>
              <th class="text-center">{{ totalStats.rbi }}</th>
              <th class="text-center">{{ totalStats.r }}</th>
              <th class="text-center">{{ totalStats.bb }}</th>
              <th class="text-center">{{ totalStats.sac }}</th>
              <th class="text-center">{{ totalStats.e }}</th>
          </template>

          <!-- Original template for cell(Lineup) -->
          <template v-slot:cell(Lineup)="data">
            <div class="playerInfo" v-if="data.item.username">
              <div class="shirt-no">
                <img src="../../assets/images/shirt.png" alt="Image" />
                <span>{{ data.item.userRole ? data.item.userRole.uniform : 0 }}</span>
              </div>
              <span class="player-name">{{ data.item.username }}</span>
            </div>
            <content-placeholders :rounded="true" v-else>
              <content-placeholders-text :lines="1" />
            </content-placeholders>
          </template>
          <template v-slot:cell(game.opponent_name)="data">
            <router-link :to="`/score-game/${data.item.game.team_id}-${data.item.game.guid}`" class="stats-link m-0">
              {{ data.item.game.opponent_name }}
          </router-link>
            <div class="played-data">
                <span class="tie" v-if="data.item.game_live == 1">
                    {{ data.item.game.team_score }} - {{ data.item.game.opponent_score }}
                </span>

                <span class="tie" v-else-if="data.item.game.win_status == 0">
                    {{ data.item.game.team_score }} - {{ data.item.game.opponent_score }}
                </span>

                <span class="win-data" v-else-if="data.item.game.win_status == 1">
                    W{{ data.item.game.team_score }} - {{ data.item.game.opponent_score }}
                </span>

                <span class="loss-data" v-else-if="data.item.game.win_status == 2">
                    L{{ data.item.game.team_score }} - {{ data.item.game.opponent_score }}
                </span>

                <span class="tie" v-else-if="data.item.game_start != 1">
                    Not Started Yet
                </span>

                <span class="tie" v-else>
                    {{ data.item.game.team_score }} - {{ data.item.game.opponent_score }}
                </span>
            </div>
          </template>

          <template v-slot:cell(game.start_date)="row">
                {{ formatDateTime( row.item.game.start_date  ) }} <br> {{ row.item.game.start_time | formatTimeToAM}}
              </template>
              <template v-slot:cell(stats.on_base_avg)="data">
                <div class="text-center pl-2">
                  {{ data.item.stats.on_base_avg !== null ? data.item.stats.on_base_avg : '-' }}
                </div>
              </template>
              <template v-slot:cell(event.eventName)="data">
                <router-link :to="{ name: 'SingleEventDetail', params: { id: data.item.game.event_id } }" class="text-center pl-2 stats-link">
                    {{ data.item.event.eventName }}
                </router-link>
              </template>
              

              <template v-slot:cell(stats.average)="data">
                <div class="text-center pl-2">
                  {{ data.item.stats.average !== null ? data.item.stats.average : '-' }}
                </div>
              </template>
          
          

        </b-table>
      </div>
    </div>
  </div>
</b-row>


    <div
      class="text-center my-3"
      v-if="showLoader == 0 && gameLineUps.length == 0"
    >
      <div class="img">
        <img src="../../assets/images/games.png" alt="" />
      </div>
      <p class="text">No statistics found.</p>
    </div>

    <div class="row no-gutters mt-3" v-if="showLoader == 1">
      <div class="col-12 p-0">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-heading />
        </content-placeholders>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
let eventType = require("../../assets/js/eventType");

export default {
  data: function () {
    return {
      associationOptions: [],
      eventTypeOptions: eventType,
      sortKey: "",
      reverse: false,
      showBase: false,
      teamDataInfo: {},
      totalNumberOfPlayers: 0,
      teamEventInfo:{},
      eventLoader: 1,
      allFilters: {
        eventType: "",
        associationFilter: "",
        eventFilter: "",
      },
      teamId: "",
    };
  },
  props: {
    gameLineUps: {
      type: Array,
    },
    totalStats: {
      type: Object,
    },
    showLoader: {
      type: Number,
    },
    selectedRoomTeam: {
      type: Object,
    },
    // teamId: {
    //   type: String,
    // },
  },
  computed: {    
    fields() {
    let baseFields = [
    { key: "game.opponent_name", label: "Opponent", sortable: false,  }, // Disable sorting for Lineup column
    {
          key: "game.start_date",
          label: "Date",
          sortable: true,
          class: "text-center pl-2" ,
        },
        { key: "event.eventName", label: "Event", sortable: true, class: "text-center pl-2"  },

      { key: "stats.ab", label: "AB", sortable: true, class: "text-center pl-2"  },
      { key: "stats.h", label: "H", sortable: true, class: "text-center pl-2"  },
      { key: "stats.one_b", label: "1B", sortable: true, class: "text-center pl-2"  },
      { key: "stats.two_b", label: "2B", sortable: true, class: "text-center pl-2"  },
      { key: "stats.three_b", label: "3B", sortable: true, class: "text-center pl-2"  },
      { key: "stats.hr", label: "HR", sortable: true, class: "text-center pl-2"  },
      { key: "stats.rbi", label: "RBI", sortable: true, class: "text-center pl-2"  },
      { key: "stats.r", label: "R", sortable: true, class: "text-center pl-2"  },
      { key: "stats.bb", label: "BB", sortable: true, class: "text-center pl-2"  },
      { key: "stats.sac", label: "SAC", sortable: true, class: "text-center pl-2"  },
      { key: "stats.e", label: "E", sortable: true, class: "text-center pl-2"  },
    ];

    if (!this.showBase) {
        baseFields.splice(3, 0, { key: "stats.on_base_avg", label: "On Base %", sortable: true, class: "text-center pl-2" });
        baseFields.splice(4, 0, { key: "stats.average", label: "Average", sortable: true, class: "text-center pl-2" });
    } else {
        baseFields.splice(3, 0, { key: "stats.on_base_avg", label: "Average", sortable: true, class: "text-center pl-2" });
    }

    return baseFields;
  },
  },
  methods: {
    getDivs(data) {
      if (data == 1) {
        this.showBase = true;
      } else if (data == 0) {
        this.showBase = false;
      }
    },
    sortBy(sortKey) {
      if (this.sortKey === sortKey) {
        this.reverse = !this.reverse;
      } else {
        this.sortKey = sortKey;
        this.reverse = true;
      }
    },
    getTotal(columnName) {
      return this.gameLineUps.reduce(
        (total, item) => total + item.stats[columnName],
        0
      );
    },
    formatDateTime(dateString) {
      const optionsDate = { month: "short", day: "numeric", year: "2-digit" }; 
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString("en-US", optionsDate);
      return `${formattedDate}`;
    },
    clearEventType() {
      this.allFilters.eventType = "";
      this.applyFilter();
    },
    clearAssociation() {
      this.allFilters.associationFilter = "";
      this.applyFilter();
    },
    clearEvents() {
      this.allFilters.eventFilter = "";
      this.applyFilter();
    },
    applyFilter() {
      this.$root.$emit("teamStatisticsFilter", this.allFilters);
    },
    getTeamEvents() {
      this.eventLoader = 1;
      const formData = new FormData();
      formData.append("team_id", this.teamId);
      axios
        .post(process.env.VUE_APP_API_URL + "event/eventTypeLeagueTournamentList", formData)
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.teamEventInfo = response.data.data;
            this.eventLoader = 0;
          } else {
            this.errorMessage = response.data.message;
            this.eventLoader = 0;
          }
        });
    },
    getAssociation() {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}getAssociation?search=${""}`,
          this.details,
          {}
        )
        .then((response) => {
          this.associationOptions = [];
          response.data.data.association.forEach((element) => {
            this.associationOptions.push(element.association_name);
          });
        });
    },
  },
  mounted() {
    this.getAssociation();
    var url = window.location.pathname;
    var segment_array = url.split("/");
    this.teamId = segment_array.pop();
     if(this.teamId)
      {    
        this.getTeamEvents();
      }
    this.$root.$on("onBaseShowAvg", (data) => {
      this.teamDataInfo = data;
      if (
        this.teamDataInfo.team &&
        this.teamDataInfo.team.show_on_base_avg == 1
      ) {
        this.showBase = true;
      }
    });
    this.$root.$on("onBaseShowAvgValue", (data) => {
      this.getDivs(data);
    });
    // this.$root.$on("HitTeamsHistory", () => {
    //   this.fetchLineups();
    // });
    // this.$root.$on("callLineupAfterUpdate", () => {
    //   this.fetchLineups();
    // });
  },
};
</script>

<style scoped>
.lds-dual-ring-media:after {
    width: 20px;
    height: 20px;
  }
.shop-products-img-loader {
    position: absolute;
    top: 50%;
    left: 90%!important;
    z-index: 99;
}
.teams-table {
  overflow: auto;
}
.teams-table table thead tr th {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
}
.loader div {
  display: contents !important;
}
.teams-table table tbody tr td {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  vertical-align: middle !important;
  text-align: center;
}
.teams-table tbody tr td div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.teams-table tbody tr td small {
  background-color: #ffffff;
  border: 1px solid #188ef5;
  width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.playerInfo{
  display: flex;
    align-items: center;
    gap: 5px;
}
.player-name {
  text-align: start;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sort-icon {
  width: 14px;
  margin-left: 2px;
  padding-bottom: 2px;
  cursor: pointer;
}
.form-group select {
  border: 1px solid #ced4da !important;
}
</style>
