var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.user)?_c('div',{},[(_vm.errorMessage)?_c('div',{staticClass:"invalid mb-26"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.posts.length)?_c('div',_vm._l((_vm.posts),function(post){return _c('div',{key:post.id,staticClass:"post-view"},[_c('div',{staticClass:"user-info",staticStyle:{"margin-bottom":"16px"}},[_c('div',{staticClass:"user-img"},[(_vm.user.user_link == post.user_link)?_c('span',[_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":"/profile","exact":""}},[(post.propicture == 'yes')?_c('img',{attrs:{"src":_vm.Storage_URL +
                    post.user_id +
                    '/profile_picture.jpg?v=' +
                    _vm.time,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/user-default-new.png"),"alt":""}})])],1):_c('span',[_c('router-link',{staticStyle:{"color":"black"},attrs:{"to":/profile/ + post.user_link,"exact":""}},[(post.propicture == 'yes')?_c('img',{attrs:{"src":_vm.Storage_URL +
                    post.user_id +
                    '/profile_picture.jpg?v=' +
                    _vm.time,"alt":""}}):_c('img',{attrs:{"src":require("../../assets/images/user-default-new.png"),"alt":""}})])],1)]),_c('div',{staticClass:"user-details mr-auto"},[(_vm.user.user_link == post.user_link)?_c('span',[_c('router-link',{staticClass:"user-name ml-0",staticStyle:{"color":"black"},attrs:{"to":"/profile","exact":""}},[_vm._v(_vm._s(post.user_name))])],1):_c('span',[_c('router-link',{staticClass:"user-name ml-0",staticStyle:{"color":"black"},attrs:{"to":/profile/ + post.user_link,"exact":""}},[_vm._v(_vm._s(post.user_name))])],1),_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(post.created_at)))])]),(_vm.user.id == post.user_id)?_c('PostEdit',{attrs:{"items":post,"openTab":1}}):_vm._e()],1),_c('div',{staticClass:"post-content"},[_c('PostContent',{attrs:{"items":post.caption}}),_c('div',{staticClass:"post-images"},[_c('div',{staticClass:"gallery-container"},[_c('lightbox',{staticClass:"newPostC",attrs:{"items":post.post_images}})],1)])],1),_c('PeopleComments',{attrs:{"items":post}})],1)}),0):_vm._e(),(_vm.noPost == 1)?_c('div',{staticClass:"see-all-seen-post-wrapper mt-0"},[_c('p',[_vm._v("No posts found.")])]):_vm._e(),(_vm.showLoader == 1)?_c('content-placeholders',{staticClass:"post-view",attrs:{"rounded":true}},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":3}})],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }