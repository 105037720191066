<template>
  <div>
    <Navbar />
    <InviteFriendsModal />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />
      <div class="shop-side p-3">
        <div class="row no-gutters">
          <div class="col-3 d-none d-lg-block">
            <div class="left-sidebar">
              <Events />
              <FriendsRequests
                v-if="showfriendRequest == 1"
                :items="this.friendsRequestsCount"
              />
              <Terms :showInviteFriend="showInviteFriend" />
            </div>
          </div>

          <div class="col-12 col-lg-9 px-lg-3">
            <Single />
          </div>
        </div>
      </div>
    </div>

    <FlashMessage />
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Events from "../components/Home/Events.vue";
import FriendsRequests from "../components/Home/FriendsRequests.vue";
import Terms from "../components/Home/Terms.vue";
import Single from "../components/Posts/Single.vue";
// import Specialists from "../components/Posts/Specialists.vue";
import InviteFriendsModal from "../components/Home/InviteFriendsModal.vue";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    Navbar,
    LeftMenu,
    Events,
    Single,
    // Specialists,
    Terms,
    FriendsRequests,
    InviteFriendsModal,
    Banner,
  },
  name: "SinglePost",

  data() {
    return {
      showInviteFriend: 0,
      showfriendRequest: 0,
      friendsRequestsCount: 0,
      grpInfo: null,
      linkGetUrl: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["getGrpData"]),
    async sideLeftView() {
      this.showInviteFriend = 0;
      let friendReq = await axios.get(`friends/friendRequestCount`);

      if (friendReq.data.statusCode == 200) {
        this.friendsRequestsCount = friendReq.data.data.count;
        if (this.friendsRequestsCount > 0) {
          this.showFriendRequestList();
        } else {
          this.showInviteButton();
        }
      } else {
        this.showInviteButton();
      }
      this.$root.$emit("FriendRequestshowLoaderOff");
    },
    showInviteButton: function () {
      this.showInviteFriend = 1;
      this.showfriendRequest = 0;
    },

    showFriendRequestList: function () {
      this.showInviteFriend = 0;
      this.showfriendRequest = 1;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.sideLeftView(1);
    this.$root.$on("FriendRequestCountCheck", () => {
      this.sideLeftView(1);
    });
  },
};
</script>
<style>
/* Add margin or padding as needed to place the banner below the Navbar */
/* .banner-wrapper {
  margin-top: 
} */
</style>