<template>
  <div>
    <div class="setting-main-wrapper">
      <div class="shop-side p-3 w-100 h-100" style="top: 0; left: 0;">
        <NotFound />
      </div>
    </div>
  </div>
</template>

<script>

import NotFound from "../components/NotFound/NotFound.vue";
export default {
  name: "PageNotFound",
  components: {
    NotFound,
  },
};
</script>

<style></style>
