<template>
  <div>
    <Navbar />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner />

      <div class="shop-side pr-3">
        <GameTimeTab />
        <LiveCard />
         <TeamInfo
          :currentUserId="currentUserId"
          :rooms="rooms"
          :teamInfoLoader="teamInfoLoader"
        />
       
      </div>
    </div>
  </div>
</template>

<script>
import LiveCard from "../components/GameTime/LiveCard.vue";
import TeamInfo from "../components/GameTime/TeamInfo.vue";
import GameTimeTab from "../components/GameTime/GameTimeTabs.vue";
import Navbar from "../components/Header/Navbar.vue";
import Banner from "../components/BannerNotify/BannerPage.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import * as firestoreService from "../database/firestore";
import { formatTimestamp } from "../utils/dates";

export default {
  name: "GameTime",

  components: {
    Navbar,
    LeftMenu,
    LiveCard,
    TeamInfo,
    Banner,
    GameTimeTab
  },

  data() {
    return {
      teamIds: [],
      currentUserId: "",
      foundCurrentUser: [],
      teamCurrentUserArray: [],
      tapArchive: 1,
      userId: localStorage.getItem("userId"),
      userName: localStorage.getItem("userName"),
      roomsPerPage: 15,
      rooms: [],
      chatTypeCheck: "team",
      roomId: "",
      roomAvatar: [],
      startRooms: null,
      endRooms: null,
      roomsLoaded: false,
      allUsers: [],
      time: this.$time,
      noChatIcon: false,
      formattedRooms: [],
      teamInfoLoader: 1,
    };
  },
  methods: {
    newLists() {
      this.foundCurrentUser = [];
      this.teamCurrentUserArray = [];
      this.tapArchive = 1;
      this.roomsPerPage = 15;
      this.chatTypeCheck = "team";
      this.roomId = "";
      this.roomAvatar = [];
      this.time = this.$time;
      this.noChatIcon = false;
      this.formattedRooms = [];
      this.teamInfoLoader = 1;
      this.rooms = [];
      this.tapArchive = 1;
      this.roomDataCount = 0;
      this.allUsersRef = [];
      this.startRooms = null;
      this.endRooms = null;
      this.roomsLoaded = false;
      this.allUsers = [];
      this.messages = [];
    },
    async fetchMoreRooms() {
      this.newLists();
      if (this.currentUserId == "") {
        const promiseUser = await firestoreService.getUserCid(this.userId);
        this.foundCurrentUser = promiseUser;
        if (this.foundCurrentUser.data[0] != undefined) {
          this.currentUserId = this.foundCurrentUser.data[0].id;
        }
      }

      if (this.currentUserId != "") {
        try {
          if (this.endRooms && !this.startRooms) {
            this.roomsLoaded = true;
            return;
          }
          if (this.currentUserId != "") {
            const query = firestoreService.roomsGameTimeQuery(
              this.currentUserId
            );

            const { data, docs } = await firestoreService.getRooms(query);
            this.roomsLoaded =
              data.length === 0 || data.length < this.roomsPerPage;
            if (this.startRooms) this.endRooms = this.startRooms;
            this.startRooms = docs[docs.length - 1];
            await this.makeUserArray(data);

            const roomList = {};
            data.forEach((room) => {
              this.teamIds.push(room.id);
              roomList[room.id] = { ...room, users: [] };
              room.users.forEach((userId) => {
                const foundUser = this.allUsers.find(
                  (user) => user?._id === userId
                );
                if (foundUser) {
                  var chechUrl;
                  var url =
                    this.$userProfileStorageURL +
                    foundUser.c_id +
                    "/profile_picture.jpg?v=" +
                    this.time;
                  this.urlExist(url, (exists) => {
                    if (exists) {
                      chechUrl = "yes";
                      foundUser.avatar = url;
                      console.log(chechUrl);
                    } else {
                      chechUrl = "no";
                      foundUser.avatar = require("../assets/images/user-default-new.png");
                    }
                  });
                  roomList[room.id].users.push(foundUser);
                }
              });
            });

            const formattedRooms = [];
            this.formattedRooms = [];

            Object.keys(roomList).forEach((key) => {
              const room = roomList[key];
              const roomUnreadCount = room.userCount.filter(
                (user) => user.id == this.currentUserId
              );

              if (room.chatType == "team") {
                room.roomName = room.teamName;
                this.roomAvatar =
                  room.teamAvatar !== ""
                    ? this.$groupAvatarStorageURL + room.teamAvatar
                    : require("../assets/images/team-avatar.png");
              }

              if (room.chatType == this.chatTypeCheck) {
                // Find if team archive
                this.teamCurrentUserArray = [];
                if (room.chatType == "team") {
                  this.teamCurrentUserArray = room.userRole.filter(
                    (user) => user.id == this.currentUserId
                  );
                }

                if (
                  (this.teamCurrentUserArray.length > 0 &&
                    this.tapArchive == this.teamCurrentUserArray[0].status) ||
                  room.chatType != "team"
                ) {
                  formattedRooms.push({
                    ...room,
                    roomId: key,
                    unreadCount:
                      roomUnreadCount[0] === undefined
                        ? 0
                        : roomUnreadCount[0].count == "0" ||
                          roomUnreadCount[0].count == 0
                        ? ""
                        : roomUnreadCount[0].count,
                    avatar: this.roomAvatar,
                    index: room.lastUpdated.seconds,
                    lastMessage: {
                      content: "",
                      timestamp: formatTimestamp(
                        new Date(room.lastUpdated.seconds * 1000),
                        room.lastUpdated
                      ),
                    },
                  });
                }
              }
            });

            this.rooms = this.rooms.concat(formattedRooms);
            this.rooms.sort((a, b) => b.lastUpdated - a.lastUpdated);
            if (!this.rooms.length) {
              this.noChatIcon = true;
            } else {
              this.noChatIcon = false;
            }
          }
          this.$root.$emit("gameTimeTeams", this.rooms, this.noChatIcon);
          this.$root.$emit("gameTimeLiveCard", this.teamIds);
          this.teamInfoLoader = 0;
        } catch (error) {
          this.teamInfoLoader = 0;
        } finally {
          this.teamInfoLoader = 0;
        }
      } else {
        this.teamInfoLoader = 0;
        this.$alert("Please check your internet connection. User not found.");
      }
    },

    // URL exist ----------------------------------------------------------------
    urlExist(url, callback) {
      const img = new Image();
      img.src = url;

      if (img.complete) {
        callback(true);
      } else {
        img.onload = () => {
          callback(true);
        };

        img.onerror = () => {
          callback(false);
        };
      }
    },

    async makeUserArray(data) {
      const roomUserIds = [];
      data.forEach((room) => {
        room.users.forEach((userId) => {
          const foundUser = this.allUsers.find((user) => user?._id === userId);
          if (!foundUser && roomUserIds.indexOf(userId) === -1) {
            roomUserIds.push(userId);
          }
        });
      });

      const rawUsers = [];
      roomUserIds.forEach((userId) => {
        const promise = firestoreService.getUser(userId);
        rawUsers.push(promise);
      });
      this.allUsers = [...this.allUsers, ...(await Promise.all(rawUsers))];
    },
  },

  mounted() {
    this.fetchMoreRooms();
    this.$root.$on("showTeamListGameTime", () => {
      this.fetchMoreRooms();
    });
  },
};
</script>
