<template>
  <div>
    <div class="data-info-wrap" v-if="teamInfoLoader == 0">
      <div class="data-info-content" v-for="team in sportTeams" :key="team.id" @click="showSchedule(team.id)">
        <div class="data-info">
          <div class="image-team">
            <img src="../../assets/loader.gif" alt="" v-if="imgLoader == 1" />
            <img
              @load="onImageLoad"
              v-if="team.teamAvatar == ''"
              src="../../assets/images/main-team-avatar.png"
               alt="" 
               />
            <img 
            @load="onImageLoad" 
            v-if="team.teamAvatar != ''" 
            :src="team.avatar" 
            alt="" 
            />
          </div>
          <div>
            <div class="name-team">{{team.teamName}}</div>
            <span class="type-team">{{team.sportType}} {{team.ageGroup}}</span>
          </div>
        </div>
        <div class="mates-play-wrap">
          <MatesData :members="team.users"/>
          <Play :teamId="team.id" />
        </div>
      </div>
    </div>
    <div class="data-info-wrap" v-if="teamInfoLoader == 0 && sportTeams.length == 0">
      <NoChat />
    </div>
    <ChatShimmers class="px-0 pb-0 pt-2" v-if="teamInfoLoader == 1"/>
  </div>
</template>

<script>
import ChatShimmers from '../Teams/chatShimmers.vue';
import NoChat from '../Teams/NoChat.vue';
import MatesData from "./MatesData.vue";
import Play from "./Play.vue";

export default {
  name: "TeamData",
  components: { MatesData, Play, NoChat, ChatShimmers },
  data() {
    return {
      sportTeams: [],
      noChatIcon: false,
      imgLoader: 1,
      searchName: '',
      searchAge: '',
      searchGender: '',
      filters: {
        name: '',
        age: '',
        gender: '',
      },
    };
  },
  props: {
    teamInfoLoader: {
      type: Number,
    },
  },
  // computed: {
  // filteredTeams() {
  //   const query = this.searchName.toLowerCase();
  //   const filtered = this.sportTeams.filter(team =>
  //     team.teamName.toLowerCase().includes(query)
  //   );
  //   return filtered;
  // },  
// },

  methods: {
    showSchedule(teamId) {
      this.$router.push(`/game-events/${teamId}`);
    },
    onImageLoad() {
      this.imgLoader = 0;
    },
    filterTeams() {
      if (this.filters.name || this.filters.age || this.filters.gender) {
        this.sportTeams = this.originalSportTeams.filter(team => {
          return (
            (!this.filters.name || team.teamName.toLowerCase().includes(this.filters.name.toLowerCase())) &&
            (!this.filters.age || team.ageGroup === this.filters.age) &&
            (!this.filters.gender || team.gender.toLowerCase() === this.filters.gender.toLowerCase())
          );
        });
      } else {
        this.sportTeams = this.originalSportTeams;
      }
    },
  },

  mounted() {
    this.$root.$on("gameTimeTeams", (team, noChatIcon) => {
      this.originalSportTeams = team;
      this.noChatIcon = noChatIcon;
      this.filterTeams();
    });

    this.$root.$on('filtersData', (filteredteams) => {
      this.filters.name = filteredteams.name,
      this.filters.age = filteredteams.age,
      this.filters.gender = filteredteams.gender,
      this.filterTeams();
    });
  },
};
</script>

<style>
</style>