<template>
  <div>
    <Navbar />
    <InviteFriendsModal />
    <FlashMessage />
    <div class="setting-main-wrapper">
      <div class="main-left-menu">
        <LeftMenu />
      </div>
      <Banner/>
      <div class="shop-side">
        <div class="shop-left-menu d-none d-lg-block p-0 main-page-left-side">
          <Events />
          <FriendsRequests
            v-if="showfriendRequest == 1"
            :items="this.friendsRequestsCount"
          />
          <Terms :showInviteFriend="showInviteFriend" />
        </div>
        <div class="shop-page-products main-page-right-side">
          <div class="row no-gutters">
            <div class="col-12 col-lg-8 pl-0 pr-lg-3">
              <PostInput />
              <PostView class="mt-3" />
            </div>

            <div class="col-4 d-none d-lg-block">
              <div class="pros-top">
                <h6 class="s-heading m-0">Specialists</h6>
                <router-link to="/pros" exact class="s-more text-primary"
                  >View More</router-link
                >
              </div>
              <Specialists />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Header/Navbar.vue";
import LeftMenu from "../components/Home/LeftMenu.vue";
import Events from "../components/Home/Events.vue";
import FriendsRequests from "../components/Home/FriendsRequests.vue";
import Terms from "../components/Home/Terms.vue";
import PostInput from "../components/Posts/PostInput.vue";
import PostView from "../components/Posts/PostView.vue";
import Specialists from "../components/Posts/Specialists.vue";
import Banner from '../components/BannerNotify/BannerPage.vue';
import InviteFriendsModal from "../components/Home/InviteFriendsModal.vue";

import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
  components: {
    Navbar,
    LeftMenu,
    Events,
    PostInput,
    PostView,
    Specialists,
    Terms,
    FriendsRequests,
    InviteFriendsModal,
    Banner
  },
  name: "Home",

  data() {
    return {
      showInviteFriend: 0,
      showfriendRequest: 0,
      friendsRequestsCount: 0,
      grpInfo: null,
      linkGetUrl: null,
    };
  },

  computed: {
    ...mapGetters("auth", ["user"]),
  },

  methods: {
    ...mapActions("auth", ["getGrpData"]),
    async sideLeftView() {
      this.showInviteFriend = 0;
      let friendReq = await axios.get(`friends/friendRequestCount`);

      if (friendReq.data.statusCode == 200) {
        this.friendsRequestsCount = friendReq.data.data.count;
        if (this.friendsRequestsCount > 0) {
          this.showFriendRequestList();
        } else {
          this.showInviteButton();
        }
      } else {
        this.showInviteButton();
      }
      this.$root.$emit("FriendRequestshowLoaderOff");
    },
    async loginUrlJoin() {
      this.linkGetUrl = localStorage.getItem("linkUrl");
      var formData = new FormData();
      formData.append("sendLinkUrl", this.linkGetUrl ? this.linkGetUrl : "");
      return axios
        .post(process.env.VUE_APP_API_URL + "invite/readLogin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            this.grpInfo = response.data.data.userByCopy;
            if (this.grpInfo != null || this.grpInfo.length != 0) {
              this.getGrpData(this.grpInfo);
              this.$router.push({ name: "PendingInvite" });
            }
          } else {
            setTimeout(() => {
              this.errorMessage = response.data.message;
            }, 500);
          }
        });
    },

    showInviteButton: function () {
      this.showInviteFriend = 1;
      this.showfriendRequest = 0;
    },

    showFriendRequestList: function () {
      this.showInviteFriend = 0;
      this.showfriendRequest = 1;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // this.loginUrlJoin();
    this.sideLeftView(1);
    this.$root.$on("FriendRequestCountCheck", () => {
      this.sideLeftView(1);
    });
  },
};
</script>
<style>
/* Add margin or padding as needed to place the banner below the Navbar */
/* .banner-wrapper {
  margin-top: 
} */
</style>
